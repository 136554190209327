<template>
  <div class="sctp-container mg-tb10 system-notice">
    <div class="mg-b10">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>网站公告</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="10" type="flex">
      <el-col :span="18">
        <div class="sctp-bg-white">
          <template v-if="noticeConfig.detail">
            <div class="card-title pad-tb20">
              <div>
                <h1 class="mg-b5 pad-lr15">{{ noticeConfig.detail.title }}</h1>
                <div class="fc-info">
                  <i class="el-icon-time"></i>
                  {{ dateToString(noticeConfig.detail.createtime) }}
                  人气：{{ noticeConfig.detail.hits || 0 }}
                </div>
              </div>
            </div>
            <div class="pad20" style="min-height: 400px;">
              <div v-html="noticeConfig.detail.content" class="rich-text"></div>
            </div>
          </template>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="sctp-bg-white">
          <h3 class="sctp-bold sctp-tc card-title pad-tb15">近期网站公告</h3>
          <div class="pad15">
            <template v-for="(notice, noticeIndex) in noticeConfig.noticeList">
              <div
                @click="noticeConfig.onNoticeItemClick(notice)"
                style="cursor:pointer;"
                class="mg-tb5 sctp-ellipsis">{{ noticeIndex + 1 }}、{{ dateToString(notice.createtime, '[MM/dd]') }}
                {{ notice.title }}
              </div>
            </template>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {common} from "@/apis/index";

export default {
  name: "systemNotice",
  props: ['id'],
  data() {
    return {
      noticeConfig: {
        detail: null,
        noticeList: [],
        onNoticeItemClick: (notice) => {
          this.goPage(`/systemNotice/${notice.id}`)
            .then(() => {
              this.noticeConfig.loadDetail();
            })
        },
        loadSystemNotice: () => {
          common.loadSystemNotice({
            page: 1,
            limit: 10
          }).then(res => {
            let {data} = res;
            this.noticeConfig.noticeList = data;
          });
        },
        loadDetail: () => {
          common.loadSystemNoticeDetail({
            id: this.id
          }).then(res => {
            let {data} = res;
            this.noticeConfig.detail = data;
          })
        }
      }
    }
  },
  methods: {},
  beforeMount() {
    this.noticeConfig.loadSystemNotice();
    this.noticeConfig.loadDetail();
  }
}
</script>

<style scoped lang="scss">
.system-notice {
  .card-title {
    background-color: #f9f9f9;
    text-align: center;
  }
}
</style>
